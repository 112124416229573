<template>
  <div>
    <button class="bg-gray-300 mx-4 p-3" v-on:click="printToPOS">Print</button>
  </div>
</template>

<script>
// import Printers from "./Printers";
// import JSPM from "jsprintmanager";
import JSPM from "../../public/JSPrintManager";
// import Vue from 'vue';

export default {
  name: "Test Printer",
  props: ["title"],
  components: {
    // Printers,
  },
  data: function () {
    return {
      print2default: true,
      printers: [],
      // selected_printer: "",
    };
  },
  methods: {
    printToPOS: function () {
      if (this.selected_printer === "" && !this.print2default) {
        alert("You must select a printer");
        return;
      }

      var cpj = new JSPM.ClientPrintJob();

      if (this.print2default) {
        cpj.clientPrinter = new JSPM.DefaultPrinter();
      } else {
        cpj.clientPrinter = new JSPM.InstalledPrinter(this.selected_printer);
      }

      //Set content to print...
      //Create ESP/POS commands for sample label
      var esc = "\x1B"; //ESC byte in hex notation
      var newLine = "\x0A"; //LF byte in hex notation
      var fullCut = esc + "m";

      var cmds = esc + "@"; //Initializes the printer (ESC @)
      cmds += esc + "!" + "\x38"; //Emphasized + Double-height + Double-width mode selected (ESC ! (8 + 16 + 32)) 56 dec => 38 hex
      cmds += "CHAPMAN XPRESS"; //text to print
      cmds += newLine;
      cmds += esc + "!" + "\x00"; //Character font A selected (ESC ! 0)

      cmds += newLine + newLine;

      cmds += "(SEREKUNDA)";
      cmds += newLine;
      cmds += "Receipt No .: 218282819828198281";
      cmds += newLine + newLine;
      cmds += "Meter No .: 07063358985";
      cmds += newLine;

      cmds += esc + "!" + "\x22";
      cmds += "MUSA JALLOW";
      cmds += esc + "!" + "\x00";

      cmds += newLine;
      cmds += "07063358985";
      cmds += newLine + newLine;
      cmds += "------------------ Token Number ---------------";
      cmds += newLine;
      cmds += esc + "1" + "\x01  ";

      // cmds += '';
      cmds += newLine;
      cmds += "********************************************";
      cmds += newLine + newLine;
      cmds += esc + "!" + "\x30"; //Emphasized + Double-height mode selected (ESC ! (16 + 8)) 24 dec => 18 hex

      cmds += "0706-3358-9851-8821-9921";
      cmds += newLine + newLine;
      cmds += esc + "!" + "\x00";

      cmds += "********************************************";
      cmds += newLine + newLine;

      cmds += "Amount Paid .:      GMD 10.00";
      cmds += newLine;
      cmds += "Fee .:      GMD 10.00";
      cmds += newLine;
      cmds += "Cashpower Amount .:      GMD 10.00";
      cmds += newLine;
      cmds += "Total Units .:     539.6kWh";
      cmds += newLine;

      cmds += "Payment Date .:     5th Feb, 2022, 9:24:11 pm";

      cmds += newLine + newLine;
      cmds += esc + "!" + "\x18"; //Emphasized + Double-height mode selected (ESC ! (16 + 8)) 24 dec => 18 hex
      cmds += "Operator: Ebrima Camara";
      cmds += newLine;
      cmds += esc + "!" + "\x00"; //Character font A selected (ESC ! 0)

      cmds += "Use Energy Wisely!";
      cmds += newLine;

      // cmds += esc + '!' + '\x38';

      cmds += newLine + newLine;
      cmds += "11/03/13  19:53:17";
      cmds += newLine + newLine;
      cmds += newLine + newLine;

      cmds += fullCut;

      cpj.printerCommands = cmds;
      //Send print job to printer!
      cpj.sendToClient();
    },

    onPrinterChange: function (value) {
      this.selected_printer = value;
      console.info("Selected printer:", value);
    },
    onInit: function () {
      console.log("IN INIT", JSPM);
      var _this = this;
      JSPM.JSPrintManager.auto_reconnect = true;
      JSPM.JSPrintManager.start();
      JSPM.JSPrintManager.WS.onStatusChanged = function () {
        _this.getPrinters().then((p) => {
          _this.printers = p;
          _this.$nextTick();
        });
      };
    },
    getPrinters: function () {
      return new Promise((ok, err) => {
        let printers = [];
        if (JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Open) {
          JSPM.JSPrintManager.getPrinters()
            .then(function (myPrinters) {
              printers = myPrinters;
              console.log(printers);
              ok(printers);
            })
            .catch((e) => err(e));
        } else {
          console.warn("JSPM WS not open");
          ok(printers);
        }
      });
    },
  },
  mounted: function () {
    this.onInit();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
